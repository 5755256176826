import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

const Wrapper = styled.div`
  padding-top: calc(64px);
  padding-bottom: 128px;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1140px;
  margin: auto;

  h2 {
    margin-left: 16px;
  }
`

export default function Container({ heading, children }) {
  return (
    <Wrapper>
      <FormattedMessage id={heading} tagName="h2" />
      {children}
    </Wrapper>
  )
}

import React from "react"
import styled from "styled-components"
import SubLayout from "../components/Sublayout"
import { graphql, Link } from "gatsby"
import { LocaleStoreContext } from "../components/LocaleStore"
import { FormattedMessage, FormattedDate } from "react-intl"
import Container from "../components/structural/Container"
import Img from "gatsby-image"

const BlogList = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  margin: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    padding-top: 16px;
  }

  h2 {
    margin: 0;
  }
`

const BlogArticlePreview = styled.div`
  width: 280px;
  background-color: white;
  margin: 8px 8px 8px 8px;
  border-radius: 10px;
`

const BlogTileHeader = styled.div`
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  background-color: #333333;

  img {
    width: 280px;
  }
`

const BlogTileContent = styled.div`
  padding: 10px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;

  h1 {
    font-weight: 100;
    font-size: 24px;
    hyphens: auto;
    margin-top: 0px;
    min-height: 58px;
    height: 80px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
  }
`

const ReadBtn = styled(Link)`
  font-size: 20px;
  margin-top: auto;
  align-self: flex-end;
`

export default function Blog({ data }) {
  const posts = data.allMarkdownRemark.edges.sort(
    (a, b) =>
      new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
  )
  return (
    <SubLayout fixedHeader>
      <Container heading="blog.heading">
        <BlogList>
          {posts.map((node) => {
            const { frontmatter, fields } = node.node
            return (
              <BlogArticlePreview key={node.node.id}>
                <LocaleStoreContext.Consumer>
                  {({ locale }) => {
                    return (
                      <>
                        <BlogTileHeader>
                          {fields ? (
                            <Img
                              fixed={fields.bloglistimg.childImageSharp.fixed}
                            />
                          ) : (
                            <p></p>
                          )}
                        </BlogTileHeader>
                        <BlogTileContent>
                          <h1>{frontmatter["title_" + locale]}</h1>
                          <h5>
                            <FormattedDate
                              value={frontmatter.date}
                              year="numeric"
                              month="long"
                              day="2-digit"
                            ></FormattedDate>
                          </h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: frontmatter["description_" + locale],
                            }}
                          ></div>
                          <ReadBtn to={frontmatter.path}>
                            <FormattedMessage id="blog.read" />
                          </ReadBtn>
                        </BlogTileContent>
                      </>
                    )
                  }}
                </LocaleStoreContext.Consumer>
              </BlogArticlePreview>
            )
          })}
        </BlogList>
      </Container>
    </SubLayout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/blog/*" } }) {
      edges {
        node {
          id
          fields {
            bloglistimg {
              childImageSharp {
                fixed(width: 280, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          frontmatter {
            date
            title_en
            description_en
            title_de
            description_de
            path
          }
        }
      }
    }
  }
`
